import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LayoutAnonymousCandidate from "../components/layout/layout-anonymous-candidate/layout-anonymous-candidate";
import {Button, Grid} from "@mui/material";
import AccordionFaq from "../components/accordion/accordion-faq.component";
//import Image from "../components/image/image.component";
import Image from "next/legacy/image";

import TitleMainPageComponent from "../components/title/title-main-page/title-main-page.component";
import {useRouter} from "next/router";
import { useTranslation } from 'next-i18next';
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import Seo from "../components/seo/seo.component";

function Index() {
    const router = useRouter()
    const {t} = useTranslation(['index']);
    return (
        <>
            <Seo title={t('_TKEY_SEO_TITLE', { ns: 'index' }) } description={t('_TKEY_SEO_DESCRIPTION', { ns: 'index' })} />
            <Box sx={{mt:{xs: 5,sm: 5,md:7,lg:7,xl:7}}} >
                <Box >
                    <Typography variant="h4" component="h1" gutterBottom  sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: {xs: "30px",sm:"30px",md:"24px",lg:"24px",xl:"24px"},
                        lineHeight: "29px",
                        letterSpacing: "0.05em",
                        textAlign:{xs: "left",sm:"left",md:"center",lg:"center",xl:"center"}
                    }}>
                        {t('_TKEY_INDEX_HEADER', {ns: 'index'})}
                    </Typography>
                </Box>
                <Grid container spacing={3} sx={{mb: 10,mt:3}}>
                    <Grid item xs={12} md={6} sx={{textAlign:'right'}}>
                        <div>
                            <Image
                                alt="panel_24_question"
                                src={'/img/home/bienvenue_portail.png'}
                                layout="responsive"
                                width={"512"}
                                height={"344"}
                            />
                        </div>
                        <Box sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: {xs: "19px",sm:"19px",md:"14px",lg:"14px",xl:"14px"},
                            lineHeight: "17px",
                            textAlign: "center",
                            letterSpacing: "0.03em",
                            color: "rgba(0, 0, 0, 0.7)",
                            mb: 3
                        }}>
                            <div dangerouslySetInnerHTML={
                                {__html: t('_TKEY_INDEX_IMAGE_1_LEGEND', {interpolation: {escapeValue: false}})}
                            } />
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "22px",
                        letterSpacing: "0.03em"
                    }}>
                        <div dangerouslySetInnerHTML={
                            {__html: t('_TKEY_INDEX_IMAGE_1_DESCIPTION_1', {interpolation: {escapeValue: false}})}
                        } />
                        <Box sx={{mt:5}} dangerouslySetInnerHTML={
                            {__html: t('_TKEY_INDEX_IMAGE_1_DESCIPTION_2', {interpolation: {escapeValue: false}})}
                        } />
                        <Box sx={{mt:5}}  dangerouslySetInnerHTML={
                            {__html: t('_TKEY_INDEX_IMAGE_1_DESCIPTION_3', {interpolation: {escapeValue: false}})}
                        }/>
                        <Box sx={{mt:5}}    dangerouslySetInnerHTML={
                            {__html: t('_TKEY_INDEX_IMAGE_1_DESCIPTION_4', {interpolation: {escapeValue: false}})}}/>
                        <Box sx={{
                            display:'flex',
                            justifyContent:{xs: "center",sm:"center",md:"right",lg:"right",xl:"right"},
                            mt:5
                        }}>
                            <Button onClick={()=>router.push('/questionnaire/orientation')} variant={'contained'}
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontStyle: "normal",
                                        fontWeight: "normal",
                                        fontSize: "18px",
                                        lineHeight: "22px",
                                        borderRadius:"15px",
                                        textTransform: 'none',
                                        textAlign:'center'
                                    }}> {t('_TKEY_BUTTON_TEXT_1')}</Button>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={12} sx={{mt:{xs: 5,sm: 5,md:7,lg:7,xl:7}}} >
                        <TitleMainPageComponent>
                            {t('_TKEY_INDEX_TITLE_1')}
                        </TitleMainPageComponent>
                        <Box sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "18px",
                            lineHeight: "22px",
                            letterSpacing: "0.03em",
                            mb: {  md:5,lg:5,xl:5}
                        }}>
                            {t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_1_LINE_1')}
                            <br/>
                            <br/>
                            {t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_1_LINE_2')}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                            <Image src={'/img/home/formation_1.png'} alt={'formation_1'}   layout="responsive"
                                   width={564}
                                   height={237} />
                        <Box sx={{display: { xs: 'none' , sm: 'none', md:'block',lg:'block' },mt:1 }}>
                            <Image src={'/img/home/formation_2.png'}
                                   alt={'formation_2'}
                                   layout="responsive"
                                   width={564}
                                   height={237}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Box  sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "18px",
                            lineHeight: "22px",
                            letterSpacing: "0.1em",
                            marginBottom: "10px"

                        }}>
                            {t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_2_PART_1')}
                        </Box>
                        <Box sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "18px",
                            lineHeight: "22px",
                            letterSpacing: "0.03em"
                        }} component={'div'}  dangerouslySetInnerHTML={
                            {__html: t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_2_PART_2', {interpolation: {escapeValue: false}})}} />
                        <div>
                            <Box sx={{display: { xs: 'block' , sm: 'block', md:'none',lg:'none'},my:3}}>
                                <Image   layout="responsive"
                                         width={564}
                                         height={237} src={'/img/home/formation_2.png'} alt={'formation_2'}  />
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box  sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "18px",
                            lineHeight: "22px",
                            letterSpacing: "0.1em",
                            marginBottom: "10px"
                        }}>
                            {t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_3_PART_1')}
                        </Box>
                        <Box sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "18px",
                            lineHeight: "22px",
                            letterSpacing: "0.03em",
                            marginLeft:'10px'
                        }} component={'div'}
                             dangerouslySetInnerHTML={
                                 {__html: t('_TKEY_BUTTON_TEXT_1_DESCRIPTION_3_PART_2', {interpolation: {escapeValue: false}})}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Image layout="responsive"
                               width={564}
                               height={295} src={'/img/home/formation_3.png'} alt={'formation_3'} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TitleMainPageComponent>
                            {t('_TKEY_INDEX_TITLE_2')}
                        </TitleMainPageComponent>
                        <AccordionFaq />
                    </Grid>
                </Grid>
            </Box>
        </>

  );
}
Index.getLayout = function getLayout(page) {
    return (
      <LayoutAnonymousCandidate >
        {page}
      </LayoutAnonymousCandidate>
  )
}

export  default Index
export async function getStaticProps() {
    return {
        props: {
            ...(await serverSideTranslations(process.env.WEB_SITE_LANGUAGE, ['index','index-faq', 'common','footer','menu'])),
        },
    };
}